import React, { useEffect, useState } from "react";
import Select from "react-select";
import { platform_type } from "../ManagePlatformCharges";
import KmRateCard from "./Range";

interface SetPlatformChargesProps {
  setPayementModes: React.Dispatch<React.SetStateAction<any>>;
  paymentModes: any;
  selectedPaymentMode: any;
  setSelectedPaymentMode: React.Dispatch<React.SetStateAction<any>>;
  setPlatformCharges: React.Dispatch<React.SetStateAction<any>>;
  isTrusteeList?: boolean;
  trusteeList?: any;
  bulkTrusteeIds?: any;
  setBulkTrusteeIds?: React.Dispatch<React.SetStateAction<any>>;
}

function SetPlatformChargesComponent({
  setPayementModes,
  paymentModes,
  selectedPaymentMode,
  setSelectedPaymentMode,
  setPlatformCharges,
  isTrusteeList = false,
  trusteeList = [],
  bulkTrusteeIds = [],
  setBulkTrusteeIds,
}: SetPlatformChargesProps) {
  let options;
  if (isTrusteeList && trusteeList.length > 0) {
    options = trusteeList.map((trustee: any, index: number) => {
      return {
        value: trustee._id,
        label: trustee.name,
        id: trustee._id,
      };
    });
  }
  const [isEmptyBulkArr, setIsEmptyBulkArr] = useState<boolean>(
    isTrusteeList ? true : false
  );

  useEffect(() => {
    const allValuesAvailable =
      bulkTrusteeIds.length > 0 && selectedPaymentMode && paymentModes;
    setIsEmptyBulkArr(!allValuesAvailable);
  }, [bulkTrusteeIds, selectedPaymentMode, paymentModes]);

  const handleChange = (selected: any) => {
    if (setBulkTrusteeIds) {
      setBulkTrusteeIds(selected);
    }
  };

  return (
    <div>
      {isTrusteeList && trusteeList.length > 0 && (
        <Select
          options={options}
          placeholder="Select Trustee"
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          onChange={handleChange}
          styles={{
            container: (provided, state) => ({
              ...provided,
              marginBottom: "10px",
            }),
          }}
        />
      )}

      <Select
        value={paymentModes}
        options={platform_type}
        onChange={(selectedOption) => {
          setPayementModes(selectedOption);
          setSelectedPaymentMode(null);
        }}
        placeholder="Select Platform Type"
        styles={{
          container: (provided, state) => ({
            ...provided,
            marginBottom: "10px",
          }),
        }}
      />

      <Select
        options={paymentModes?.value}
        value={selectedPaymentMode}
        onChange={(selectedOption) => {
          setSelectedPaymentMode(selectedOption);
        }}
        placeholder="Select Payment Method"
        styles={{
          container: (provided, state) => ({
            ...provided,
            marginBottom: "10px",
          }),
        }}
      />

      <KmRateCard
        updateMDR={setPlatformCharges}
        isTrusteeList={isTrusteeList}
        isEmptyBulkArr={isEmptyBulkArr}
      />
    </div>
  );
}

export default SetPlatformChargesComponent;
